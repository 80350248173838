import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "path": "/developer/writing-forms-react",
  "date": "2016-07-17",
  "title": "WRITING FORMS IN REACT AND REDUX",
  "author": "admin",
  "tags": ["development", "css", "html"],
  "featuredImage": "feature.jpg",
  "excerpt": "rWiting forms in React and Redux can be challenging, having to deal with forms and fields. In this article we are going to build couple of form using an exisiting library sepcifically for Redux called react-redux-form."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Let’s start off by downloading necessary packages.`}</p>
    <h2>{`Installation`}</h2>
    <p>{`We need Node.js installed to begin with. Follow the link, download `}<a parentName="p" {...{
        "href": "https://nodejs.org/en/download/"
      }}>{`Node.js`}</a>{` for installation instructions.`}</p>
    <p>{`Now create a folder for your project and then run the following command in command line.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`npm init
`}</code></pre>
    <p>{`Open up the console and install following packages:`}</p>
    <h3>{`React & Redux`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`npm install react react-dom redux ––save
`}</code></pre>
    <h3>{`React Redux Form`}</h3>
    <p>{`Also we need to install `}<inlineCode parentName="p">{`react-redux-form`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`npm install react-redux-redux ––save
`}</code></pre>
    <h3>{`Redux Thunk`}</h3>
    <p>{`The `}<inlineCode parentName="p">{`redux-thunk`}</inlineCode>{` middleware is useful for determining the proper action to dispatch based on the state, as well as dispatching actions or sequences of actions asynchronously.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`npm install redux-thunk ––save
`}</code></pre>
    <h3>{`Babel`}</h3>
    <p>{`We need Babel to write our shiny ES6 code.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`npm install babel-cli ––save
npm install babel-preset-react babel-preset-es2015 ––save
`}</code></pre>
    <h3>{`Webpack`}</h3>
    <p>{`We are going to use `}<inlineCode parentName="p">{`Webpack`}</inlineCode>{` to build our project.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`npm install webpack webpack-dev-server babel-loader ––save–dev
`}</code></pre>
    <h2>{`Setting Up`}</h2>
    <h3>{`Create `}<inlineCode parentName="h3">{`.babelrc`}</inlineCode></h3>
    <p>{`In order to create a preset to use Babel, we have to create a `}<inlineCode parentName="p">{`.babelrc`}</inlineCode>{` file.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "presets" : ["es2015"]
}
`}</code></pre>
    <h3>{`Create React Components`}</h3>
    <p>{`Create a folder called `}<inlineCode parentName="p">{`components`}</inlineCode>{` and create a file called `}<inlineCode parentName="p">{`FormField.js`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import React from 'react';
import { Field } from 'react-redux-form';
import '../styles/form.css';
 
const FormField = ({ model, label, type }) => {
  return (
    <Field model={model} className="field">
      <label>{label}</label>
      <input type={type} />
    </Field>
  );
};
 
export default FormField;
`}</code></pre>
    <h3>{`Create Styles`}</h3>
    <p>{`Create a folder called `}<inlineCode parentName="p">{`styles`}</inlineCode>{` under the `}<inlineCode parentName="p">{`src`}</inlineCode>{` directory to hold our styles.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`label {
    width: 5rem;
    display: inline-block;
}
 
button {
    padding: .5rem 1rem;
    width: 13.2rem;
    margin-top: .5rem;
}
 
form {
    font-family: Helvetica, Arial;
    font-size: .85rem;
}
 
.field {
    margin: .4rem 0;
}
`}</code></pre>
    <h3>{`Create containers`}</h3>
    <p>{`Containers are higher order components that Redux will be connecting to. They hold the components which usually don’t need to know about the store.`}</p>
    <p>{`Create a file called `}<inlineCode parentName="p">{`LoginForm.js`}</inlineCode>{` under the `}<inlineCode parentName="p">{`containers`}</inlineCode>{` folder.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import React from 'react';
import { connect } from 'react-redux';
import { Field, Form, actions } from 'react-redux-form';
import FormField from '../components/FormField';
 
const LoginForm = ({ user }) => {
  return (
    <form>
       <FormField model="user.username" label="Username" type="text" />
       <FormField model="user.password" label="Password" type="password"/>
 
       <button>Log in</button>
     </form>
  );
};
 
const selector = (state) => ({ user: state.user });
 
export default connect(selector)(LoginForm);
`}</code></pre>
    <h3>{`Create Store`}</h3>
    <p>{`The store will contain the reducers handling logic and connecting to the external end points etc.`}</p>
    <p>{`We import `}<inlineCode parentName="p">{`react-redux-form`}</inlineCode>{` redux-thunk for handling side-effects (asynchronous calls to external APIs etc.)`}</p>
    <h3>{`Create Root`}</h3>
    <p>{`The top level component that renders to the DOM will be our root file. Let’s call it `}<inlineCode parentName="p">{`index.js`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import React from 'react';
import ReactDOM from 'react-dom';
import UserForm from './containers/UserForm';
import { createStore, combineReducers } from 'redux';
import { Provider } from 'react-redux';
import { modelReducer, formReducer } from 'react-redux-form';
import store from './store.js';
 
const reducer = combineReducers({
  user: modelReducer('user'),
  userForm: formReducer('user')
});
 
ReactDOM.render(
  <Provider store={store}>
    <UserForm />
  </Provider>,
  document.getElementById('root')
);
`}</code></pre>
    <h3>{`Create Web Page`}</h3>
    <p>{`We need a web page in order to render our root React component into.`}</p>
    <p>{`Create a folder called `}<inlineCode parentName="p">{`public`}</inlineCode>{` and a folder underneath it called `}<inlineCode parentName="p">{`index.html`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <title>React Redux Form</title>
</head>
<body>
  <div id="root"></div>
  <script src="/static/bundle.js"></script>
</body>
</html>
`}</code></pre>
    <h3>{`Running the App`}</h3>
    <p>{`Fire up a command line console and run the following command.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`npm start
`}</code></pre>
    <p>{`Now you can open up a browser instance and go to http://localhost:3000 see your app working.`}</p>
    <p><img parentName="p" {...{
        "src": "/images/2016-07-17-writing-forms-react/react-redux-form.png",
        "alt": "Redux Form"
      }}></img></p>
    <h3>{`Conclusion`}</h3>
    <p>{`You can find the source code in `}<a parentName="p" {...{
        "href": "https://github.com/szaranger/react-redux-form-demo"
      }}>{`Github`}</a>{` here.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      